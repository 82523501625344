import { css } from "@emotion/css";
import MuiCircularProgress from "@material-ui/core/CircularProgress";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
/** Props used for the {@link CircularProgress} component */
export interface CircularProgressProps {
    /**
     * The size of the component.
     * Only two sizes are currently supported, small and large.
     */
    size: "small" | "large";
}
const progressRootClass = "octopus-circular-progress";
/**
 * - Docs: https://www.octopus.design/932c0f1a9/p/913f63-progress
 * - Showcase: https://components.octopus.design/?story=progress--circular
 * @param CircularProgressProps
 * @param CircularProgressProps.size - The size of the component
 * @example
 * ```tsx
 * <CircularProgressProps size="small" />
 * ```
 */
export const CircularProgress: React.FC<CircularProgressProps> = ({ size }) => {
    return <MuiCircularProgress className={progressRootClass} classes={{ circle: rootStyles }} role="progressbar" aria-label="Progress" variant="indeterminate" size={size === "small" ? 22 : 44} thickness={4}/>;
};
CircularProgress.displayName = "CircularProgress"
const rootStyles = css({
    [`.${progressRootClass} &`]: {
        color: themeTokens.color.progressBar.foreground.circular,
    },
});
CircularProgress.displayName = "CircularProgress";

import type { Client } from "../client";
import type { DeploymentResource } from "../resources";
import type { GetDeploymentProcessActionTypesBffResponse } from "../resources/getDeploymentProcessActionTypesBffResponse";
import type { TaskState } from "../resources/taskState";
import type { ListArgs } from "./basicRepository";
import { BasicRepository } from "./basicRepository";
type DeploymentListArgs = {
    projects?: string[];
    environments?: string[];
    tenants?: string[];
    channels?: string[];
    taskState?: TaskState;
} & ListArgs;
export class DeploymentRepository extends BasicRepository<DeploymentResource, DeploymentResource, DeploymentListArgs> {
    constructor(client: Client) {
        super("Deployments", client);
    }
    override create(resource: DeploymentResource, args?: {}): Promise<DeploymentResource> {
        return this.client.post<DeploymentResource>(this.client.getLink(this.collectionLinkName), resource, args);
    }
    getDeploymentProcessActionTypes(deploymentProcessId: string): Promise<GetDeploymentProcessActionTypesBffResponse> {
        return this.client.get<GetDeploymentProcessActionTypesBffResponse>(`~/bff/spaces/{spaceId}/deploymentProcess/actionTypes?deploymentProcessId=${encodeURIComponent(deploymentProcessId)}`);
    }
}

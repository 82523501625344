import { css } from "@emotion/css";
import { resetStyles } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { KeyboardEvent } from "react";
import React from "react";
interface ListProps<TItem> {
    items: TItem[];
    renderListItem: (item: TItem, index: number, tabIndex: number | undefined, ref: React.Ref<ListItemElement> | undefined) => React.ReactElement;
    accessibleName: string;
}
function ListInternal<TItem>({ items, renderListItem, accessibleName }: ListProps<TItem>, ref: React.ForwardedRef<ListElement>) {
    const listRef = React.useRef<HTMLUListElement>(null);
    const [currentItemIndex, setCurrentItemIndex] = React.useState<number>(0);
    const currentItemElement = React.useRef<ListItemElement>(null);
    React.useImperativeHandle(ref, () => ({
        focus: () => {
            currentItemElement.current?.focus();
        },
    }));
    const onKeyDown = (event: KeyboardEvent) => {
        const { key } = event;
        if (key === "ArrowUp" || key === "ArrowDown") {
            event.preventDefault();
            setCurrentItemIndex((i) => {
                const direction = key === "ArrowUp" ? -1 : 1;
                return (i + direction + items.length) % items.length;
            });
        }
        else if (key === "Home" || key === "End") {
            event.preventDefault();
            setCurrentItemIndex(key === "Home" ? 0 : items.length - 1);
        }
    };
    React.useEffect(() => {
        if (listRef.current?.contains(document.activeElement)) {
            currentItemElement.current?.focus();
        }
    }, [currentItemIndex]);
    React.useEffect(() => {
        setCurrentItemIndex(0);
    }, [items]);
    return (<ul className={listStyles} aria-label={accessibleName} onKeyDown={onKeyDown} ref={listRef}>
            {items.map((item, index) => renderListItem(item, index, index === currentItemIndex ? 0 : -1, index === currentItemIndex ? currentItemElement : undefined))}
        </ul>);
}
// The List has generic props which are lost when wrapping with forwardRef.
// This type assertion is a workaround to restore the generic props.
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const List = React.forwardRef(ListInternal) as <TItem>(p: ListProps<TItem> & {
    ref?: React.Ref<ListElement>;
}) => React.ReactElement;
export interface ListElement {
    focus: () => void;
}
export interface ListItemElement {
    focus: () => void;
}
const listStyles = css({
    ...resetStyles.ul,
    display: "flex",
    flexDirection: "column",
    gap: space[1],
});

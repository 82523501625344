import * as globals from "./globals";
import type { OctopusTheme } from "./OctopusTheme";
export const darkTheme: OctopusTheme = {
    "colorInterfaceBorderPrimary": globals["colorScales"]["navy"]["700"],
    "colorInterfaceBorderSecondary": globals["colorScales"]["navy"]["900"],
    "colorInterfaceBorderTertiary": globals["colorScales"]["navy"]["600"],
    "colorInterfaceBorderLink": globals["colorScales"]["blue"]["400"],
    "colorInterfaceBackgroundPrimary": globals["colorScales"]["navy"]["900"],
    "colorInterfaceBackgroundSecondary": globals["colorScales"]["navy"]["800"],
    "colorInterfaceBackgroundSubtle": globals["colorScales"]["navy"]["700"],
    "colorInterfaceBackgroundLink": globals["colorScales"]["blue"]["400"],
    "colorInterfaceIconPrimary": globals["colorScales"]["navy"]["100"],
    "colorInterfaceIconSecondary": globals["colorScales"]["navy"]["200"],
    "colorInterfaceIconSubtle": globals["colorScales"]["navy"]["300"],
    "colorInterfaceIconDisabled": globals["colorScales"]["navy"]["500"],
    "colorInterfaceIconLink": globals["colorScales"]["blue"]["400"],
    "colorInterfaceTextPrimary": globals["colorScales"]["navy"]["100"],
    "colorInterfaceTextSecondary": globals["colorScales"]["navy"]["200"],
    "colorInterfaceTextSubtle": globals["colorScales"]["navy"]["300"],
    "colorInterfaceTextDisabled": globals["colorScales"]["navy"]["500"],
    "colorInterfaceTextLink": globals["colorScales"]["blue"]["400"],
    "colorTableCellTextPrimary": globals["colorScales"]["navy"]["200"],
    "colorTableCellTextSubtle": globals["colorScales"]["navy"]["300"],
    "colorTableCellTextDisabled": globals["colorScales"]["navy"]["400"],
    "colorTableCellTextLink": globals["colorScales"]["blue"]["400"],
    "colorTableCellBorderPrimary": globals["colorScales"]["navy"]["700"],
    "colorTableCellBorderSecondary": globals["colorScales"]["navy"]["900"],
    "colorTableCellBackgroundDefault": globals["colorScales"]["navy"]["800"],
    "colorTableCellBackgroundHover": globals["colorScales"]["navy"]["700"],
    "colorTableCellIconPrimary": globals["colorScales"]["navy"]["200"],
    "colorTableSortTextPrimary": globals["colorScales"]["navy"]["200"],
    "colorTableSortTextSubtle": globals["colorScales"]["navy"]["300"],
    "colorTableSortTextDisabled": globals["colorScales"]["navy"]["400"],
    "colorTableSortTextLink": globals["colorScales"]["blue"]["400"],
    "colorTableSortBorderPrimary": globals["colorScales"]["navy"]["700"],
    "colorTableSortBorderSecondary": globals["colorScales"]["navy"]["900"],
    "colorTableSortBackgroundDefault": globals["colorScales"]["navy"]["800"],
    "colorTableSortBackgroundHover": globals["colorScales"]["navy"]["700"],
    "colorTableSortIconPrimary": globals["colorScales"]["navy"]["200"],
    "colorAlertTextInfo": globals["colorScales"]["blue"]["300"],
    "colorAlertTextSuccess": globals["colorScales"]["green"]["300"],
    "colorAlertTextWarning": globals["colorScales"]["yellow"]["300"],
    "colorAlertTextDanger": globals["colorScales"]["red"]["300"],
    "colorAlertTextBody": globals["colorScales"]["white"],
    "colorAlertIconInfo": globals["colorScales"]["blue"]["600"],
    "colorAlertIconSuccess": globals["colorScales"]["green"]["600"],
    "colorAlertIconWarning": globals["colorScales"]["yellow"]["600"],
    "colorAlertIconDanger": globals["colorScales"]["red"]["600"],
    "colorAlertIconDismiss": globals["colorScales"]["white"],
    "colorAlertBorderInfo": globals["colorScales"]["blue"]["400"],
    "colorAlertBorderSuccess": globals["colorScales"]["green"]["400"],
    "colorAlertBorderWarning": globals["colorScales"]["yellow"]["400"],
    "colorAlertBorderDanger": globals["colorScales"]["red"]["400"],
    "colorAlertBackgroundInfo": globals["colorScales"]["blue"]["800"],
    "colorAlertBackgroundSuccess": globals["colorScales"]["green"]["800"],
    "colorAlertBackgroundWarning": globals["colorScales"]["yellow"]["800"],
    "colorAlertBackgroundDanger": globals["colorScales"]["red"]["800"],
    "colorMenuListTextPrimary": globals["colorScales"]["navy"]["100"],
    "colorMenuListTextSecondary": globals["colorScales"]["navy"]["200"],
    "colorMenuListTextHighlight": globals["colorScales"]["blue"]["400"],
    "colorMenuListBackgroundDefault": globals["colorScales"]["navy"]["800"],
    "colorMenuListBackgroundHover": globals["colorScales"]["navy"]["700"],
    "colorMenuListIconPrimary": globals["colorScales"]["navy"]["400"],
    "colorTextFieldTextDefault": globals["colorScales"]["navy"]["200"],
    "colorTextFieldTextHover": globals["colorScales"]["navy"]["200"],
    "colorTextFieldTextActive": globals["colorScales"]["white"],
    "colorTextFieldTextSmallTextDefault": globals["colorScales"]["navy"]["200"],
    "colorTextFieldTextSmallTextActive": globals["colorScales"]["blue"]["400"],
    "colorTextFieldTextSmallTextDanger": globals["colorScales"]["red"]["400"],
    "colorTextFieldTextLabelDefault": globals["colorScales"]["navy"]["200"],
    "colorTextFieldTextLabelActive": globals["colorScales"]["blue"]["400"],
    "colorTextFieldTextLabelDanger": globals["colorScales"]["red"]["400"],
    "colorTextFieldBorderDefault": globals["colorScales"]["navy"]["500"],
    "colorTextFieldBorderHover": globals["colorScales"]["navy"]["300"],
    "colorTextFieldBorderActive": globals["colorScales"]["blue"]["400"],
    "colorTextFieldBorderDanger": globals["colorScales"]["red"]["400"],
    "colorTextFieldIconDefault": globals["colorScales"]["navy"]["200"],
    "colorChipFilledTextPrimary": globals["colorScales"]["navy"]["200"],
    "colorChipFilledTextSecondary": globals["colorScales"]["purple"]["200"],
    "colorChipFilledTextInfo": globals["colorScales"]["blue"]["200"],
    "colorChipFilledTextSuccess": globals["colorScales"]["green"]["200"],
    "colorChipFilledTextWarning": globals["colorScales"]["yellow"]["200"],
    "colorChipFilledTextDanger": globals["colorScales"]["red"]["200"],
    "colorChipFilledBackgroundPrimary": globals["colorScales"]["navy"]["700"],
    "colorChipFilledBackgroundSecondary": globals["colorScales"]["purple"]["700"],
    "colorChipFilledBackgroundInfo": globals["colorScales"]["blue"]["700"],
    "colorChipFilledBackgroundSuccess": globals["colorScales"]["green"]["700"],
    "colorChipFilledBackgroundWarning": globals["colorScales"]["yellow"]["700"],
    "colorChipFilledBackgroundDanger": globals["colorScales"]["red"]["700"],
    "colorChipFilledIconPrimary": globals["colorScales"]["navy"]["200"],
    "colorChipFilledIconSecondary": globals["colorScales"]["purple"]["200"],
    "colorChipFilledIconInfo": globals["colorScales"]["blue"]["200"],
    "colorChipFilledIconSuccess": globals["colorScales"]["green"]["200"],
    "colorChipFilledIconWarning": globals["colorScales"]["yellow"]["200"],
    "colorChipFilledIconDanger": globals["colorScales"]["red"]["200"],
    "colorChipOutlinedBorderPrimary": globals["colorScales"]["navy"]["400"],
    "colorChipOutlinedBorderSecondary": globals["colorScales"]["purple"]["400"],
    "colorChipOutlinedBorderInfo": globals["colorScales"]["blue"]["400"],
    "colorChipOutlinedBorderSuccess": globals["colorScales"]["green"]["400"],
    "colorChipOutlinedBorderWarning": globals["colorScales"]["yellow"]["400"],
    "colorChipOutlinedBorderDanger": globals["colorScales"]["red"]["400"],
    "colorChipOutlinedTextPrimary": globals["colorScales"]["navy"]["400"],
    "colorChipOutlinedTextSecondary": globals["colorScales"]["purple"]["400"],
    "colorChipOutlinedTextInfo": globals["colorScales"]["blue"]["400"],
    "colorChipOutlinedTextSuccess": globals["colorScales"]["green"]["400"],
    "colorChipOutlinedTextWarning": globals["colorScales"]["yellow"]["400"],
    "colorChipOutlinedTextDanger": globals["colorScales"]["red"]["400"],
    "colorChipOutlinedIconPrimary": globals["colorScales"]["navy"]["400"],
    "colorChipOutlinedIconSecondary": globals["colorScales"]["purple"]["400"],
    "colorChipOutlinedIconInfo": globals["colorScales"]["blue"]["400"],
    "colorChipOutlinedIconSuccess": globals["colorScales"]["green"]["400"],
    "colorChipOutlinedIconWarning": globals["colorScales"]["yellow"]["400"],
    "colorChipOutlinedIconDanger": globals["colorScales"]["red"]["400"],
    "colorBackgroundPrimary": globals["colorScales"]["navy"]["900"],
    "colorBackgroundSecondary": globals["colorScales"]["navy"]["800"],
    "colorButtonBackgroundPrimaryHover": "rgba(26, 119, 202, 0.16)",
    "colorButtonBackgroundPrimaryPressed": "rgba(26, 119, 202, 0.32)",
    "colorButtonBackgroundPrimaryDisabled": globals["colorScales"]["navy"]["800"],
    "colorButtonBackgroundSecondaryHover": "rgba(0, 135, 77, 0.16)",
    "colorButtonBackgroundSecondaryPressed": "rgba(0, 135, 77, 0.32)",
    "colorButtonBackgroundSecondaryDisabled": globals["colorScales"]["navy"]["800"],
    "colorButtonBackgroundSubtleHover": "rgba(85, 121, 153, 0.16)",
    "colorButtonBackgroundSubtlePressed": "rgba(85, 121, 153, 0.32)",
    "colorButtonBackgroundSubtleDisabled": globals["colorScales"]["navy"]["800"],
    "colorButtonBackgroundDangerDefault": globals["colorScales"]["red"]["500"],
    "colorButtonBackgroundDangerHover": globals["colorScales"]["red"]["600"],
    "colorButtonBackgroundDangerPressed": globals["colorScales"]["red"]["700"],
    "colorButtonBackgroundDangerDisabled": globals["colorScales"]["navy"]["800"],
    "colorButtonIconPrimaryDefault": globals["colorScales"]["blue"]["400"],
    "colorButtonIconPrimaryDisabled": globals["colorScales"]["navy"]["600"],
    "colorButtonIconSecondaryDefault": globals["colorScales"]["green"]["400"],
    "colorButtonIconSecondaryDisabled": globals["colorScales"]["navy"]["600"],
    "colorButtonIconSubtleDefault": globals["colorScales"]["navy"]["400"],
    "colorButtonIconSubtleDisabled": globals["colorScales"]["navy"]["600"],
    "colorButtonIconDangerDefault": globals["colorScales"]["white"],
    "colorButtonIconDangerDisabled": globals["colorScales"]["navy"]["600"],
    "colorButtonIconLinkDefault": globals["colorScales"]["blue"]["400"],
    "colorButtonIconLinkHover": globals["colorScales"]["blue"]["500"],
    "colorButtonIconLinkDisabled": globals["colorScales"]["navy"]["600"],
    "colorButtonTextPrimaryDefault": globals["colorScales"]["blue"]["400"],
    "colorButtonTextPrimaryDisabled": globals["colorScales"]["navy"]["600"],
    "colorButtonTextSecondaryDefault": globals["colorScales"]["green"]["400"],
    "colorButtonTextSecondaryDisabled": globals["colorScales"]["navy"]["600"],
    "colorButtonTextSubtleDefault": globals["colorScales"]["navy"]["400"],
    "colorButtonTextSubtleDisabled": globals["colorScales"]["navy"]["600"],
    "colorButtonTextDangerDefault": globals["colorScales"]["white"],
    "colorButtonTextDangerDisabled": globals["colorScales"]["navy"]["600"],
    "colorButtonTextLinkDefault": globals["colorScales"]["blue"]["400"],
    "colorButtonTextLinkHover": globals["colorScales"]["blue"]["500"],
    "colorButtonTextLinkDisabled": globals["colorScales"]["navy"]["600"],
    "colorButtonBorderPrimaryDefault": globals["colorScales"]["blue"]["500"],
    "colorButtonBorderPrimaryDisabled": globals["colorScales"]["navy"]["800"],
    "colorButtonBorderSecondaryDefault": globals["colorScales"]["green"]["500"],
    "colorButtonBorderSecondaryDisabled": globals["colorScales"]["navy"]["800"],
    "colorButtonBorderSubtleDefault": globals["colorScales"]["navy"]["500"],
    "colorButtonBorderSubtleDisabled": globals["colorScales"]["navy"]["800"],
    "colorModalBackgroundPrimary": globals["colorScales"]["grey"]["800"],
    "colorModalOverlay": "rgba(17, 26, 35, 0.56)",
    "colorProgressBarForegroundLinear": globals["colorScales"]["blue"]["600"],
    "colorProgressBarForegroundCircular": globals["colorScales"]["blue"]["500"],
    "colorProgressBarBackgroundLinear": globals["colorScales"]["blue"]["300"],
    "colorProgressBarTextPrimary": globals["colorScales"]["white"],
    "colorNavigationBackgroundPrimary": globals["colorScales"]["navy"]["900"],
    "colorNavigationBackgroundSecondary": globals["colorScales"]["navy"]["100"],
    "colorNavigationButtonBackgroundPrimaryDefault": globals["colorScales"]["navy"]["800"],
    "colorNavigationButtonBackgroundPrimaryHover": globals["colorScales"]["blue"]["500"],
    "colorNavigationButtonBackgroundPrimaryPressed": globals["colorScales"]["blue"]["500"],
    "colorNavigationButtonTextPrimary": globals["colorScales"]["white"],
    "colorNavigationButtonIconPrimary": globals["colorScales"]["white"],
    "colorNavigationLinkBackgroundPrimaryHover": globals["colorScales"]["blue"]["500"],
    "colorNavigationLinkBackgroundPrimaryPressed": globals["colorScales"]["blue"]["600"],
    "colorNavigationLinkBackgroundPrimaryActive": globals["colorScales"]["blue"]["500"],
    "colorNavigationLinkTextPrimary": globals["colorScales"]["white"],
    "colorNavigationLinkIconPrimary": globals["colorScales"]["white"],
    "colorPaginationBackgroundDefault": globals["colorScales"]["navy"]["900"],
    "colorPaginationBackgroundHover": globals["colorScales"]["navy"]["500"],
    "colorPaginationBackgroundActive": globals["colorScales"]["blue"]["400"],
    "colorPaginationBackgroundDisabled": globals["colorScales"]["navy"]["900"],
    "colorPaginationTextDefault": globals["colorScales"]["navy"]["100"],
    "colorPaginationTextHover": globals["colorScales"]["white"],
    "colorPaginationTextActive": globals["colorScales"]["navy"]["900"],
    "colorPaginationTextDisabled": globals["colorScales"]["navy"]["600"],
    "colorPaginationIconDefault": globals["colorScales"]["navy"]["100"],
    "colorPaginationIconHover": globals["colorScales"]["white"],
    "colorPaginationIconActive": globals["colorScales"]["navy"]["900"],
    "colorPaginationIconDisabled": globals["colorScales"]["navy"]["600"],
    "colorMockMenuHover": "rgba(15, 36, 52, 0.9)",
    "colorMockMenuTernaryText": globals["colorScales"]["navy"]["200"],
    "colorMockMenuPrimary": globals["colorScales"]["blue"]["300"],
    "colorMockMenuLinkText": globals["colorScales"]["blue"]["300"],
    "shadowExtraSmall": "0 1px 8px 0 rgba(0,0,0,0.12), 0 1px 2px 0 rgba(0,0,0,0.06)",
    "shadowSmall": "0 1px 1px 0 rgba(0,0,0,0.16), 0 1px 8px 0 rgba(0,0,0,0.16)",
    "shadowMedium": "0 0 4px 0 rgba(0,0,0,0.24), 0 6px 12px 0 rgba(0,0,0,0.30)",
    "shadowLarge": "0 2px 6px 0 rgba(0,0,0,0.26), 0 24px 32px -4px rgba(0,0,0,0.42)",
    "shadowFocusDefault": "0 0 0 3px #90CEFF"
};

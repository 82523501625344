export interface KubernetesRawYamlGitSourceComponentProperties {
    "Octopus.Action.KubernetesContainers.Git.Credentials.Type": string;
    "Octopus.Action.KubernetesContainers.Git.Credentials.Id": string;
    "Octopus.Action.KubernetesContainers.Git.Url": string;
    "Octopus.Action.KubernetesContainers.Git.DefaultBranch": string;
    "Octopus.Action.KubernetesContainers.CustomResourceYamlFileName": string;
}
export class KubernetesRawYamlGitSourceComponentKeyValues {
    public static readonly CredentialsType: keyof KubernetesRawYamlGitSourceComponentProperties = "Octopus.Action.KubernetesContainers.Git.Credentials.Type";
    public static readonly CredentialsId: keyof KubernetesRawYamlGitSourceComponentProperties = "Octopus.Action.KubernetesContainers.Git.Credentials.Id";
    public static readonly Url: keyof KubernetesRawYamlGitSourceComponentProperties = "Octopus.Action.KubernetesContainers.Git.Url";
    public static readonly DefaultBranch: keyof KubernetesRawYamlGitSourceComponentProperties = "Octopus.Action.KubernetesContainers.Git.DefaultBranch";
    public static readonly CustomResourceYamlFileName: keyof KubernetesRawYamlGitSourceComponentProperties = "Octopus.Action.KubernetesContainers.CustomResourceYamlFileName";
}

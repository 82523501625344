import type { ShowLinkAsActive } from "@octopusdeploy/routing";
import { useIsUrlActive, useOctopusLinkComponent } from "@octopusdeploy/routing";
import React from "react";
import { navigationBarTextLinkStyles } from "./navigationBarStyles";
export interface NavigationBarLinkProps {
    href: string;
    showLinkAsActive?: ShowLinkAsActive;
    label: string;
    accessibleName?: string;
}
export function NavigationBarLink({ href, showLinkAsActive, label, accessibleName }: NavigationBarLinkProps) {
    const Link = useOctopusLinkComponent();
    const isUrlActive = useIsUrlActive();
    const isActive = showLinkAsActive === "never" ? false : isUrlActive(href, showLinkAsActive === "if path matches exactly");
    return (<Link className={navigationBarTextLinkStyles} aria-current={isActive ? "page" : undefined} href={href} aria-label={accessibleName}>
            {label}
        </Link>);
}

import React from "react";
import { ButtonIcon } from "./ButtonIcon";
import { getButtonStyles } from "./buttonStyles";
export interface ButtonProps {
    label: string;
    onClick: () => void;
    importance: ButtonImportance;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
}
export function Button({ label, onClick, importance, leftIcon, rightIcon }: ButtonProps) {
    const buttonStyles = getButtonStyles(importance);
    return (<button className={buttonStyles} onClick={() => onClick()}>
            {leftIcon && <ButtonIcon position="left" icon={leftIcon}/>}
            {label}
            {rightIcon && <ButtonIcon position="right" icon={rightIcon}/>}
        </button>);
}
export const buttonVariants = ["primary", "secondary"] as const;
export type ButtonImportance = (typeof buttonVariants)[number];

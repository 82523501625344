import type { ShowLinkAsActive } from "@octopusdeploy/routing";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import React from "react";
import { NavigationBarLink } from "./NavigationBarLink";
export interface NavigationBarItemProps {
    item: NavigationBarItemData;
}
export interface NavigationBarLinkItemData {
    type: "link";
    label: string;
    href: string;
    showLinkAsActive?: ShowLinkAsActive;
}
export interface NavigationBarCustomItemData {
    type: "custom";
    key: string;
    content: React.ReactElement;
    fallbackLink?: Omit<NavigationBarLinkItemData, "type">;
}
export type NavigationBarItemData = NavigationBarLinkItemData | NavigationBarCustomItemData;
export function NavigationBarItem({ item }: NavigationBarItemProps) {
    const { type } = item;
    switch (type) {
        case "link":
            return <NavigationBarLink label={item.label} href={item.href} showLinkAsActive={item.showLinkAsActive}/>;
        case "custom":
            return item.content;
        default:
            exhaustiveCheck(type, `Unsupported navigation bar item type "${type}"`);
    }
}

import { css, cx } from "@emotion/css";
import { borderRadius, space, themeTokens, text } from "@octopusdeploy/design-system-tokens";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import { resetStyles } from "../../utils/resetStyles";
import type { ButtonImportance } from "./Button";
export function getButtonStyles(variant: ButtonImportance) {
    const buttonVariantStyles = getButtonVariantStyles(variant);
    return cx([baseButtonStyles, buttonVariantStyles]);
}
const baseButtonStyles = css({
    ...resetStyles.button,
    font: text.button.default.base,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transitionTimingFunction: "ease-in-out",
    transitionProperty: "background-color, border-color, outline, color, fill",
    transitionDuration: "0.2s",
    borderRadius: borderRadius.medium,
    paddingInlineStart: space[4],
    paddingInlineEnd: space[4],
    whiteSpace: "nowrap",
});
function getButtonVariantStyles(variant: ButtonImportance): string {
    const variantLookup = getVariantLookupValue(variant);
    return css({
        color: themeTokens.color.button.text[variantLookup].default,
        border: `solid 1px ${themeTokens.color.button.border[variantLookup].default}`,
        height: "2.5rem",
        boxSizing: "border-box",
        lineHeight: "1.5rem",
        font: text.button.default.base,
        ":hover": {
            background: themeTokens.color.button.background[variantLookup].hover,
        },
        ":focus-visible": {
            background: themeTokens.color.button.background[variantLookup].hover,
        },
        ":active": {
            background: themeTokens.color.button.background[variantLookup].pressed,
        },
        ":disabled": {
            color: themeTokens.color.button.text[variantLookup].disabled,
            background: themeTokens.color.button.background[variantLookup].disabled,
            borderColor: themeTokens.color.button.border[variantLookup].disabled,
        },
    });
}
function getVariantLookupValue(buttonVariant: ButtonImportance): keyof Pick<typeof themeTokens.color.button.background, "primary" | "secondary"> {
    switch (buttonVariant) {
        case "primary":
            return "primary";
        case "secondary":
            return "secondary";
        default:
            exhaustiveCheck(buttonVariant, `Could not find lookup value for unknown variant: ${buttonVariant}`);
    }
}

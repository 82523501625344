/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { History, LocationDescriptorObject } from "history";
type LocationDescriptor = History.LocationDescriptor;
import { generatePath } from "react-router-dom";
import { escapeReactRoute } from "./escapeReactParameter";
export function resolvePathWithSpaceId(to: LocationDescriptor, spaceId: string | undefined): LocationDescriptor {
    if (isLocationDescriptorObject(to)) {
        return { ...to, pathname: resolveStringPathWithSpaceId(to.pathname!, spaceId) };
    }
    return resolveStringPathWithSpaceId(to, spaceId);
}
export function isLocationDescriptorObject(descriptor: LocationDescriptor): descriptor is LocationDescriptorObject {
    return typeof descriptor !== "string" && typeof descriptor !== "undefined" && typeof descriptor.pathname !== "undefined";
}
const FakeSpaceId = "Spaces-0";
export function resolveStringPathWithSpaceId(path: string, spaceId: string | null | undefined): string {
    // path contains space id parameter so it needs to be resolved even if space id is not provided
    spaceId = spaceId ? spaceId : FakeSpaceId;
    const encodedPath = encodeAllPathElementsExceptSpaceID(path);
    const link = generatePath(encodedPath, { spaceId });
    return spaceId === FakeSpaceId ? link.replace(FakeSpaceId, "").replace("//", "/") : link;
}
/**
 * The only route parameter we can possibly have at this point is the space id, which is the first part of any URL.
 * Any other components of the URL that have characters special to react (like colons etc) must be literal and
 * not interpreted as react path parameters. So escape any part of the url that has special characters, leaving the
 * space id parameter in place.
 * @param path The path to escape
 */
function encodeAllPathElementsExceptSpaceID(path: string) {
    const segments = path.split("/");
    const firstPathSegment = path.indexOf("/");
    return segments
        .map((val, index) => {
        // The space id parameter is either the first or second array item depending on whether the path started with a slash
        if (index <= (firstPathSegment === 0 ? 2 : 1)) {
            return val;
        }
        return escapeReactRoute(val);
    })
        .join("/");
}

import { css, cx } from "@emotion/css";
import MaterialMenuItem from "@material-ui/core/MenuItem";
import { themeTokens, space } from "@octopusdeploy/design-system-tokens";
import type { MouseEventHandler, PropsWithChildren } from "react";
import React, { forwardRef } from "react";
import type { MenuTargetAriaAttributes } from "../../Menu/useMenuState";
export type MenuItemProps = EnabledMenuItemProps | DisabledMenuItemProps;
interface CommonMenuItemProps {
    isSelected?: boolean;
    compact?: boolean;
}
export interface EnabledMenuItemProps extends CommonMenuItemProps {
    onClick: MouseEventHandler;
    autoFocus?: boolean;
    disabled?: false;
    title?: string;
    menuButtonAttributes?: MenuTargetAriaAttributes;
}
export interface DisabledMenuItemProps extends CommonMenuItemProps {
    // Note: we also don't need autoFocus here
    disabled: true;
    disableReason?: string;
}
function isDisabledMenuItemProps(props: MenuItemProps): props is DisabledMenuItemProps {
    return props.disabled === true;
}
// The forwardRef here is not important, but needed to prevent console errors that would otherwise say a ref hasn't been appropriately forwarded.
// This requirement has been removed in later versions of material-ui (v5 onwards).
// See https://github.com/mui-org/material-ui/pull/25691/files#diff-ad4b8459eb1cd3c5e6882eb705e3e341e551bd7bf4ab9a6941ef12017eb1cb06L177-L188
export const MenuItemButton = forwardRef<HTMLButtonElement, PropsWithChildren<MenuItemProps>>((props, forwardedRef) => {
    const onClick = "onClick" in props ? props.onClick : undefined;
    const classes = cx(menuItemStyles, { [compactMenuItemStyles]: props.compact });
    if (isDisabledMenuItemProps(props)) {
        //We need to wrap the menu item in a ToolTip when it's disabled to show the reason as a disabled menu item will also not
        //show the tooltip from the title
        return (<MaterialMenuItem classes={{ root: cx(disabledMenuItemStyles, classes), selected: selectedMenuItemStyles }} component={"button"} button={true} ref={forwardedRef} disabled={props.disabled} title={props.disableReason}>
                {props.children}
            </MaterialMenuItem>);
    }
    const { autoFocus, children, disabled, menuButtonAttributes } = props;
    return (<MaterialMenuItem classes={{ root: classes, selected: selectedMenuItemStyles }} selected={props.isSelected} component={"button"} button={true} autoFocus={autoFocus} disabled={disabled} onClick={onClick} ref={forwardedRef} title={props.title} {...menuButtonAttributes}>
            {children}
        </MaterialMenuItem>);
});
MenuItemButton.displayName = "MenuItemButton";
const menuItemStyles = css({
    "&.MuiMenuItem-root": {
        width: "100%",
        minHeight: space[8],
        "&.MuiListItem-button": {
            "&:hover": {
                backgroundColor: themeTokens.color.mock.menu.hover,
            },
            "&:focus": {
                backgroundColor: themeTokens.color.mock.menu.hover,
            },
        },
    },
});
const compactMenuItemStyles = css({
    "&.MuiMenuItem-root": {
        minHeight: "auto",
    },
});
const selectedMenuItemStyles = css({
    "&.MuiMenuItem-root.Mui-selected": {
        color: themeTokens.color.mock.menu.primary,
        backgroundColor: "unset",
    },
});
//We need this to make the title tooltip to appear for disabled items, this needs to be as specific as possible
//to override MUI internal styles taking precedence
const disabledMenuItemStyles = css({
    "&.MuiMenuItem-root": {
        "&.Mui-disabled": {
            pointerEvents: "auto",
        },
    },
});

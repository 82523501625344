import React from "react";
type HTMLAnchorAttributes = React.AnchorHTMLAttributes<HTMLAnchorElement>;
interface OctopusRoutingContextValue {
    Link: ReturnType<typeof React.forwardRef<HTMLAnchorElement, HTMLAnchorAttributes>>;
    useIsUrlActive: (path: string, exact: boolean | undefined) => boolean;
}
const OctopusRoutingContext = React.createContext<OctopusRoutingContextValue | undefined>(undefined);
export interface OctopusRoutingProviderProps extends OctopusRoutingContextValue {
    children: React.ReactNode;
}
export function OctopusRoutingProvider({ Link, useIsUrlActive, children }: OctopusRoutingProviderProps) {
    const contextValue = React.useMemo(() => ({
        Link,
        useIsUrlActive,
    }), [Link, useIsUrlActive]);
    return <OctopusRoutingContext.Provider value={contextValue}>{children}</OctopusRoutingContext.Provider>;
}
export function useOctopusLinkComponent() {
    const contextValue = React.useContext(OctopusRoutingContext);
    if (contextValue === undefined) {
        throw new Error("Context value not provided via a OctopusRoutingProvider");
    }
    return contextValue.Link;
}
export function useIsUrlActive() {
    const contextValue = React.useContext(OctopusRoutingContext);
    if (contextValue === undefined) {
        throw new Error("Context value not provided via a OctopusRoutingProvider");
    }
    return contextValue.useIsUrlActive;
}
export type ShowLinkAsActive = "never" | "if path matches exactly" | "if path partially matches";

import { Divider } from "@material-ui/core";
import type { ShowLinkAsActive } from "@octopusdeploy/routing";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import type { ReactNode, Ref } from "react";
import React, { forwardRef, useCallback } from "react";
import { noOp } from "../..//utils/noOp";
import { MenuItemButton } from "./MenuItemButton/MenuItemButton";
import { MenuItemExternalLink } from "./MenuItemExternalLink/MenuItemExternalLink";
import { MenuItemInformation } from "./MenuItemInformation/MenuItemInformation";
import { MenuItemInternalLink } from "./MenuItemInternalLink/MenuItemInternalLink";
import type { LinkSize } from "./MenuItemInternalLink/MenuItemInternalLink";
import { MenuItemToggle } from "./MenuItemToggle/MenuItemToggle";
import { NestedMenu } from "./NestedMenu/NestedMenu";
export interface ButtonMenuItem {
    type: "button";
    label: string;
    onClick: () => void;
}
export interface InternalLinkMenuItem {
    type: "internal-link";
    label: string;
    path: string;
    showAsActive?: ShowLinkAsActive; // Defaults to "never"
    onClick?: () => void;
}
export interface ExternalLinkMenuItem {
    type: "external-link";
    label: string;
    href: string;
    onClick?: () => void;
}
export interface ToggleMenuItem {
    type: "toggle";
    label: string;
    isEnabled: boolean;
    setIsEnabled: (isEnabled: boolean) => void;
}
export interface InformationMenuItem {
    type: "information";
    key?: string;
    content: ReactNode;
}
export interface NestedMenuItems {
    type: "nested-menu";
    label: string;
    children: SimpleMenuItem[];
}
export const dividerMenuItem = { type: "divider" } as const;
export type MenuItemDivider = typeof dividerMenuItem;
export type SimpleMenuItem = ButtonMenuItem | InternalLinkMenuItem | ExternalLinkMenuItem | NestedMenuItems | ToggleMenuItem | InformationMenuItem | MenuItemDivider;
interface SimpleMenuItemsProps {
    items: SimpleMenuItem[];
    compact?: boolean;
    onClose?: () => void;
    autoFocus?: boolean;
}
// The forwardRef here is not important, but needed to prevent console errors that would otherwise say a ref hasn't been appropriately forwarded.
// This requirement has been removed in later versions of material-ui (v5 onwards).
// See https://github.com/mui-org/material-ui/pull/25691/files#diff-ad4b8459eb1cd3c5e6882eb705e3e341e551bd7bf4ab9a6941ef12017eb1cb06L177-L188
export const SimpleMenuItems = forwardRef(({ autoFocus, onClose, items, compact = false }: SimpleMenuItemsProps, ref: Ref<unknown>) => (<>
        {items.map((item, index) => {
        const key = item.type === "information" ? item.key ?? index : item.type === "divider" ? index : item.label;
        return <SimpleMenuItemComponent key={key} item={item} onClose={onClose ?? noOp} autoFocus={index === 0 && Boolean(autoFocus)} compact={compact}/>;
    })}
    </>));
SimpleMenuItems.displayName = "SimpleMenuItems";
interface SimpleMenuItemProps {
    item: SimpleMenuItem;
    onClose: () => void;
    autoFocus: boolean;
    compact: boolean;
}
function SimpleMenuItemComponent({ item, autoFocus, onClose, compact }: SimpleMenuItemProps) {
    switch (item.type) {
        case "button":
            return <SimpleMenuItemButton item={item} onClose={onClose} autoFocus={autoFocus} compact={compact}/>;
        case "internal-link":
            return <SimpleMenuItemInternalLink item={item} onClose={onClose} autoFocus={autoFocus} size={compact ? "compact" : "default"}/>;
        case "external-link":
            return <SimpleMenuItemExternalLink item={item} onClose={onClose} autoFocus={autoFocus} compact={compact}/>;
        case "nested-menu":
            return <NestedMenuItemsComponent item={item} onClose={onClose} autoFocus={autoFocus} compact={compact}/>;
        case "toggle":
            return <ToggleMenuItemComponent item={item} autoFocus={autoFocus} compact={compact}/>;
        case "information":
            return <InformationMenuItemComponent item={item} compact={compact}/>;
        case "divider":
            return <Divider />;
        default:
            exhaustiveCheck(item, "Unexpected simple menu item type");
    }
}
interface SimpleMenuItemButtonProps {
    item: ButtonMenuItem;
    onClose: () => void;
    autoFocus: boolean;
    compact: boolean;
}
function SimpleMenuItemButton({ item, onClose, autoFocus, compact }: SimpleMenuItemButtonProps) {
    const itemOnClick = item.onClick;
    const onClick = useCallback(() => {
        itemOnClick();
        onClose();
    }, [itemOnClick, onClose]);
    return (<MenuItemButton onClick={onClick} autoFocus={autoFocus} compact={compact}>
            {item.label}
        </MenuItemButton>);
}
SimpleMenuItemButton.displayName = "SimpleMenuItemButton";
interface SimpleMenuItemInternalLinkProps {
    item: InternalLinkMenuItem;
    onClose: () => void;
    autoFocus: boolean;
    size: LinkSize;
}
function SimpleMenuItemInternalLink({ item, onClose, autoFocus, size }: SimpleMenuItemInternalLinkProps) {
    const itemOnClick = item.onClick;
    const onClick = useCallback(() => {
        itemOnClick?.();
        onClose();
    }, [itemOnClick, onClose]);
    return <MenuItemInternalLink onClick={onClick} autoFocus={autoFocus} label={item.label} showLinkAsActive={item.showAsActive} path={item.path} size={size}/>;
}
SimpleMenuItemInternalLink.displayName = "SimpleMenuItemInternalLink";
interface SimpleMenuItemExternalLinkProps {
    item: ExternalLinkMenuItem;
    onClose: () => void;
    autoFocus: boolean;
    compact: boolean;
}
function SimpleMenuItemExternalLink({ item, onClose, autoFocus, compact }: SimpleMenuItemExternalLinkProps) {
    const itemOnClick = item.onClick;
    const onClick = useCallback(() => {
        itemOnClick?.();
        onClose();
    }, [itemOnClick, onClose]);
    return <MenuItemExternalLink href={item.href} label={item.label} onClick={onClick} autoFocus={autoFocus} compact={compact}/>;
}
SimpleMenuItemExternalLink.displayName = "SimpleMenuItemExternalLink";
interface NestedMenuItemsProps {
    item: NestedMenuItems;
    onClose: () => void;
    autoFocus: boolean;
    compact: boolean;
}
function NestedMenuItemsComponent({ item, onClose, autoFocus, compact }: NestedMenuItemsProps) {
    return <NestedMenu onClose={onClose} autoFocus={autoFocus} label={item.label} childMenuItems={item.children} compact={compact}/>;
}
NestedMenuItemsComponent.displayName = "NestedMenuItemsComponent";
interface ToggleMenuItemProps {
    item: ToggleMenuItem;
    autoFocus: boolean;
    compact: boolean;
}
function ToggleMenuItemComponent({ item: { setIsEnabled, isEnabled, label }, autoFocus, compact }: ToggleMenuItemProps) {
    return <MenuItemToggle isEnabled={isEnabled} label={label} setIsEnabled={setIsEnabled} autoFocus={autoFocus} compact={compact}/>;
}
ToggleMenuItemComponent.displayName = "ToggleMenuItemComponent";
interface InformationMenuItemProps {
    item: InformationMenuItem;
    compact: boolean;
}
function InformationMenuItemComponent({ item, compact }: InformationMenuItemProps) {
    return <MenuItemInformation compact={compact}>{item.content}</MenuItemInformation>;
}
InformationMenuItemComponent.displayName = "InformationMenuItemComponent";

import { useLocation, useParams } from "react-router";
import { isUrlActive } from "./isUrlActive";
export function useIsPortalUrlActive(targetUrl: string, exact: boolean | undefined) {
    const location = useLocation();
    const routeParams = useParams<{
        spaceId: string | undefined;
    }>();
    const spaceId = routeParams.spaceId ?? undefined;
    const path = location.pathname;
    return isUrlActive(path, spaceId, targetUrl, exact);
}

import { css } from "@emotion/css";
import { space, themeTokens, borderRadius, text } from "@octopusdeploy/design-system-tokens";
import { resetStyles } from "../../utils/resetStyles";
const navigationBarItemStyles = css({
    font: text.navigation.link.medium,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    transitionTimingFunction: "ease-in-out",
    transitionProperty: "background-color, color",
    transitionDuration: "0.2s",
    height: "2.5rem",
    minWidth: "2.5rem",
    borderRadius: borderRadius.medium,
    background: "transparent",
    ":focus-visible": {
        background: themeTokens.color.navigation.link.background.primary.hover,
        boxShadow: themeTokens.shadow.focus.default,
        zIndex: 1,
    },
    ":hover": {
        background: themeTokens.color.navigation.link.background.primary.hover,
    },
    ":active": {
        background: themeTokens.color.navigation.link.background.primary.pressed,
    },
});
const navigationBarBaseLinkStyles = css(resetStyles.anchor, {
    "&[aria-current=page]": {
        background: themeTokens.color.navigation.link.background.primary.active,
    },
}, navigationBarItemStyles);
export const navigationBarTextLinkStyles = css(navigationBarBaseLinkStyles, {
    paddingInlineStart: space[4],
    paddingInlineEnd: space[4],
});
export const navigationBarIconLinkStyles = navigationBarBaseLinkStyles;
const navigationBarBaseButtonStyles = css(resetStyles.button, {
    "&[aria-expanded=true]": {
        background: themeTokens.color.navigation.link.background.primary.active,
    },
}, navigationBarItemStyles);
export const navigationBarTextButtonStyles = css(navigationBarBaseButtonStyles, {
    paddingInlineStart: space[4],
    paddingInlineEnd: space[4],
    gap: space[3],
});
export const navigationBarIconButtonStyles = navigationBarBaseButtonStyles;

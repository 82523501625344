export const color = {
    "interface": {
        "border": {
            "primary": "var(--colorInterfaceBorderPrimary)",
            "secondary": "var(--colorInterfaceBorderSecondary)",
            "tertiary": "var(--colorInterfaceBorderTertiary)",
            "link": "var(--colorInterfaceBorderLink)"
        },
        "background": {
            "primary": "var(--colorInterfaceBackgroundPrimary)",
            "secondary": "var(--colorInterfaceBackgroundSecondary)",
            "subtle": "var(--colorInterfaceBackgroundSubtle)",
            "link": "var(--colorInterfaceBackgroundLink)"
        },
        "icon": {
            "primary": "var(--colorInterfaceIconPrimary)",
            "secondary": "var(--colorInterfaceIconSecondary)",
            "subtle": "var(--colorInterfaceIconSubtle)",
            "disabled": "var(--colorInterfaceIconDisabled)",
            "link": "var(--colorInterfaceIconLink)"
        },
        "text": {
            "primary": "var(--colorInterfaceTextPrimary)",
            "secondary": "var(--colorInterfaceTextSecondary)",
            "subtle": "var(--colorInterfaceTextSubtle)",
            "disabled": "var(--colorInterfaceTextDisabled)",
            "link": "var(--colorInterfaceTextLink)"
        }
    },
    "table": {
        "cell": {
            "text": {
                "primary": "var(--colorTableCellTextPrimary)",
                "subtle": "var(--colorTableCellTextSubtle)",
                "disabled": "var(--colorTableCellTextDisabled)",
                "link": "var(--colorTableCellTextLink)"
            },
            "border": {
                "primary": "var(--colorTableCellBorderPrimary)",
                "secondary": "var(--colorTableCellBorderSecondary)"
            },
            "background": {
                "default": "var(--colorTableCellBackgroundDefault)",
                "hover": "var(--colorTableCellBackgroundHover)"
            },
            "icon": {
                "primary": "var(--colorTableCellIconPrimary)"
            }
        },
        "sort": {
            "text": {
                "primary": "var(--colorTableSortTextPrimary)",
                "subtle": "var(--colorTableSortTextSubtle)",
                "disabled": "var(--colorTableSortTextDisabled)",
                "link": "var(--colorTableSortTextLink)"
            },
            "border": {
                "primary": "var(--colorTableSortBorderPrimary)",
                "secondary": "var(--colorTableSortBorderSecondary)"
            },
            "background": {
                "default": "var(--colorTableSortBackgroundDefault)",
                "hover": "var(--colorTableSortBackgroundHover)"
            },
            "icon": {
                "primary": "var(--colorTableSortIconPrimary)"
            }
        }
    },
    "alert": {
        "text": {
            "info": "var(--colorAlertTextInfo)",
            "success": "var(--colorAlertTextSuccess)",
            "warning": "var(--colorAlertTextWarning)",
            "danger": "var(--colorAlertTextDanger)",
            "body": "var(--colorAlertTextBody)"
        },
        "icon": {
            "info": "var(--colorAlertIconInfo)",
            "success": "var(--colorAlertIconSuccess)",
            "warning": "var(--colorAlertIconWarning)",
            "danger": "var(--colorAlertIconDanger)",
            "dismiss": "var(--colorAlertIconDismiss)"
        },
        "border": {
            "info": "var(--colorAlertBorderInfo)",
            "success": "var(--colorAlertBorderSuccess)",
            "warning": "var(--colorAlertBorderWarning)",
            "danger": "var(--colorAlertBorderDanger)"
        },
        "background": {
            "info": "var(--colorAlertBackgroundInfo)",
            "success": "var(--colorAlertBackgroundSuccess)",
            "warning": "var(--colorAlertBackgroundWarning)",
            "danger": "var(--colorAlertBackgroundDanger)"
        }
    },
    "menuList": {
        "text": {
            "primary": "var(--colorMenuListTextPrimary)",
            "secondary": "var(--colorMenuListTextSecondary)",
            "highlight": "var(--colorMenuListTextHighlight)"
        },
        "background": {
            "default": "var(--colorMenuListBackgroundDefault)",
            "hover": "var(--colorMenuListBackgroundHover)"
        },
        "icon": {
            "primary": "var(--colorMenuListIconPrimary)"
        }
    },
    "textField": {
        "text": {
            "default": "var(--colorTextFieldTextDefault)",
            "hover": "var(--colorTextFieldTextHover)",
            "active": "var(--colorTextFieldTextActive)",
            "smallText": {
                "default": "var(--colorTextFieldTextSmallTextDefault)",
                "active": "var(--colorTextFieldTextSmallTextActive)",
                "danger": "var(--colorTextFieldTextSmallTextDanger)"
            },
            "label": {
                "default": "var(--colorTextFieldTextLabelDefault)",
                "active": "var(--colorTextFieldTextLabelActive)",
                "danger": "var(--colorTextFieldTextLabelDanger)"
            }
        },
        "border": {
            "default": "var(--colorTextFieldBorderDefault)",
            "hover": "var(--colorTextFieldBorderHover)",
            "active": "var(--colorTextFieldBorderActive)",
            "danger": "var(--colorTextFieldBorderDanger)"
        },
        "icon": {
            "default": "var(--colorTextFieldIconDefault)"
        }
    },
    "chip": {
        "filled": {
            "text": {
                "primary": "var(--colorChipFilledTextPrimary)",
                "secondary": "var(--colorChipFilledTextSecondary)",
                "info": "var(--colorChipFilledTextInfo)",
                "success": "var(--colorChipFilledTextSuccess)",
                "warning": "var(--colorChipFilledTextWarning)",
                "danger": "var(--colorChipFilledTextDanger)"
            },
            "background": {
                "primary": "var(--colorChipFilledBackgroundPrimary)",
                "secondary": "var(--colorChipFilledBackgroundSecondary)",
                "info": "var(--colorChipFilledBackgroundInfo)",
                "success": "var(--colorChipFilledBackgroundSuccess)",
                "warning": "var(--colorChipFilledBackgroundWarning)",
                "danger": "var(--colorChipFilledBackgroundDanger)"
            },
            "icon": {
                "primary": "var(--colorChipFilledIconPrimary)",
                "secondary": "var(--colorChipFilledIconSecondary)",
                "info": "var(--colorChipFilledIconInfo)",
                "success": "var(--colorChipFilledIconSuccess)",
                "warning": "var(--colorChipFilledIconWarning)",
                "danger": "var(--colorChipFilledIconDanger)"
            }
        },
        "outlined": {
            "border": {
                "primary": "var(--colorChipOutlinedBorderPrimary)",
                "secondary": "var(--colorChipOutlinedBorderSecondary)",
                "info": "var(--colorChipOutlinedBorderInfo)",
                "success": "var(--colorChipOutlinedBorderSuccess)",
                "warning": "var(--colorChipOutlinedBorderWarning)",
                "danger": "var(--colorChipOutlinedBorderDanger)"
            },
            "text": {
                "primary": "var(--colorChipOutlinedTextPrimary)",
                "secondary": "var(--colorChipOutlinedTextSecondary)",
                "info": "var(--colorChipOutlinedTextInfo)",
                "success": "var(--colorChipOutlinedTextSuccess)",
                "warning": "var(--colorChipOutlinedTextWarning)",
                "danger": "var(--colorChipOutlinedTextDanger)"
            },
            "icon": {
                "primary": "var(--colorChipOutlinedIconPrimary)",
                "secondary": "var(--colorChipOutlinedIconSecondary)",
                "info": "var(--colorChipOutlinedIconInfo)",
                "success": "var(--colorChipOutlinedIconSuccess)",
                "warning": "var(--colorChipOutlinedIconWarning)",
                "danger": "var(--colorChipOutlinedIconDanger)"
            }
        }
    },
    "background": {
        "primary": "var(--colorBackgroundPrimary)",
        "secondary": "var(--colorBackgroundSecondary)"
    },
    "button": {
        "background": {
            "primary": {
                "hover": "var(--colorButtonBackgroundPrimaryHover)",
                "pressed": "var(--colorButtonBackgroundPrimaryPressed)",
                "disabled": "var(--colorButtonBackgroundPrimaryDisabled)"
            },
            "secondary": {
                "hover": "var(--colorButtonBackgroundSecondaryHover)",
                "pressed": "var(--colorButtonBackgroundSecondaryPressed)",
                "disabled": "var(--colorButtonBackgroundSecondaryDisabled)"
            },
            "subtle": {
                "hover": "var(--colorButtonBackgroundSubtleHover)",
                "pressed": "var(--colorButtonBackgroundSubtlePressed)",
                "disabled": "var(--colorButtonBackgroundSubtleDisabled)"
            },
            "danger": {
                "default": "var(--colorButtonBackgroundDangerDefault)",
                "hover": "var(--colorButtonBackgroundDangerHover)",
                "pressed": "var(--colorButtonBackgroundDangerPressed)",
                "disabled": "var(--colorButtonBackgroundDangerDisabled)"
            }
        },
        "icon": {
            "primary": {
                "default": "var(--colorButtonIconPrimaryDefault)",
                "disabled": "var(--colorButtonIconPrimaryDisabled)"
            },
            "secondary": {
                "default": "var(--colorButtonIconSecondaryDefault)",
                "disabled": "var(--colorButtonIconSecondaryDisabled)"
            },
            "subtle": {
                "default": "var(--colorButtonIconSubtleDefault)",
                "disabled": "var(--colorButtonIconSubtleDisabled)"
            },
            "danger": {
                "default": "var(--colorButtonIconDangerDefault)",
                "disabled": "var(--colorButtonIconDangerDisabled)"
            },
            "link": {
                "default": "var(--colorButtonIconLinkDefault)",
                "hover": "var(--colorButtonIconLinkHover)",
                "disabled": "var(--colorButtonIconLinkDisabled)"
            }
        },
        "text": {
            "primary": {
                "default": "var(--colorButtonTextPrimaryDefault)",
                "disabled": "var(--colorButtonTextPrimaryDisabled)"
            },
            "secondary": {
                "default": "var(--colorButtonTextSecondaryDefault)",
                "disabled": "var(--colorButtonTextSecondaryDisabled)"
            },
            "subtle": {
                "default": "var(--colorButtonTextSubtleDefault)",
                "disabled": "var(--colorButtonTextSubtleDisabled)"
            },
            "danger": {
                "default": "var(--colorButtonTextDangerDefault)",
                "disabled": "var(--colorButtonTextDangerDisabled)"
            },
            "link": {
                "default": "var(--colorButtonTextLinkDefault)",
                "hover": "var(--colorButtonTextLinkHover)",
                "disabled": "var(--colorButtonTextLinkDisabled)"
            }
        },
        "border": {
            "primary": {
                "default": "var(--colorButtonBorderPrimaryDefault)",
                "disabled": "var(--colorButtonBorderPrimaryDisabled)"
            },
            "secondary": {
                "default": "var(--colorButtonBorderSecondaryDefault)",
                "disabled": "var(--colorButtonBorderSecondaryDisabled)"
            },
            "subtle": {
                "default": "var(--colorButtonBorderSubtleDefault)",
                "disabled": "var(--colorButtonBorderSubtleDisabled)"
            }
        }
    },
    "modal": {
        "background": {
            "primary": "var(--colorModalBackgroundPrimary)"
        },
        "overlay": "var(--colorModalOverlay)"
    },
    "progressBar": {
        "foreground": {
            "linear": "var(--colorProgressBarForegroundLinear)",
            "circular": "var(--colorProgressBarForegroundCircular)"
        },
        "background": {
            "linear": "var(--colorProgressBarBackgroundLinear)"
        },
        "text": {
            "primary": "var(--colorProgressBarTextPrimary)"
        }
    },
    "navigation": {
        "button": {
            "background": {
                "primary": {
                    "default": "var(--colorNavigationButtonBackgroundPrimaryDefault)",
                    "hover": "var(--colorNavigationButtonBackgroundPrimaryHover)",
                    "pressed": "var(--colorNavigationButtonBackgroundPrimaryPressed)"
                }
            },
            "text": {
                "primary": "var(--colorNavigationButtonTextPrimary)"
            },
            "icon": {
                "primary": "var(--colorNavigationButtonIconPrimary)"
            }
        },
        "background": {
            "primary": "var(--colorNavigationBackgroundPrimary)",
            "secondary": "var(--colorNavigationBackgroundSecondary)"
        },
        "link": {
            "background": {
                "primary": {
                    "hover": "var(--colorNavigationLinkBackgroundPrimaryHover)",
                    "pressed": "var(--colorNavigationLinkBackgroundPrimaryPressed)",
                    "active": "var(--colorNavigationLinkBackgroundPrimaryActive)"
                }
            },
            "text": {
                "primary": "var(--colorNavigationLinkTextPrimary)"
            },
            "icon": {
                "primary": "var(--colorNavigationLinkIconPrimary)"
            }
        }
    },
    "pagination": {
        "background": {
            "default": "var(--colorPaginationBackgroundDefault)",
            "hover": "var(--colorPaginationBackgroundHover)",
            "active": "var(--colorPaginationBackgroundActive)",
            "disabled": "var(--colorPaginationBackgroundDisabled)"
        },
        "text": {
            "default": "var(--colorPaginationTextDefault)",
            "hover": "var(--colorPaginationTextHover)",
            "active": "var(--colorPaginationTextActive)",
            "disabled": "var(--colorPaginationTextDisabled)"
        },
        "icon": {
            "default": "var(--colorPaginationIconDefault)",
            "hover": "var(--colorPaginationIconHover)",
            "active": "var(--colorPaginationIconActive)",
            "disabled": "var(--colorPaginationIconDisabled)"
        }
    },
    "mock": {
        "menu": {
            "hover": "var(--colorMockMenuHover)",
            "ternaryText": "var(--colorMockMenuTernaryText)",
            "primary": "var(--colorMockMenuPrimary)",
            "linkText": "var(--colorMockMenuLinkText)"
        }
    }
} as const;
export const shadow = {
    "extraSmall": "var(--shadowExtraSmall)",
    "small": "var(--shadowSmall)",
    "medium": "var(--shadowMedium)",
    "large": "var(--shadowLarge)",
    "focus": {
        "default": "var(--shadowFocusDefault)"
    }
} as const;

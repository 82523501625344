import { css, cx } from "@emotion/css";
import { colorScales, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { isNotNull } from "@octopusdeploy/type-utils";
import throttle from "lodash.throttle";
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { isHTMLElement } from "../../utils/isHTMLElement";
import { resetStyles } from "../../utils/resetStyles";
import { ChevronDownIcon } from "../Icon";
import { SimpleMenu, useMenuState } from "../Menu";
import type { InternalLinkMenuItem, SimpleMenuItem } from "../MenuItems";
import { NavigationBarActions } from "./NavigationBarActions";
import type { NavigationBarActionData } from "./NavigationBarActions";
import { NavigationBarButton } from "./NavigationBarButton";
import { NavigationBarIconButton } from "./NavigationBarIconButton";
import { NavigationBarIconLink } from "./NavigationBarIconLink";
import type { NavigationBarItemData } from "./NavigationBarItem";
import { NavigationBarItem } from "./NavigationBarItem";
export interface NavigationBarProps {
    logo?: React.ReactElement;
    items: NavigationBarItemData[];
    actions?: NavigationBarActionData[];
}
export function NavigationBar({ logo, items, actions }: NavigationBarProps) {
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    return (<nav className={cx(navigationBarStyles, isLargerThanIpad ? largeNavigationBarStyles : smallNavigationBarStyles)}>
            {logo}
            {isLargerThanIpad ? <NavigationBarLinksList items={items}/> : <MobileNavigationLinks items={items}/>}
            {actions && (<NavigationBarActionsContainer>
                    <NavigationBarActions items={actions}/>
                </NavigationBarActionsContainer>)}
        </nav>);
}
NavigationBar.IconButton = NavigationBarIconButton;
NavigationBar.IconLink = NavigationBarIconLink;
interface MobileNavigationLinksProps {
    items: NavigationBarItemData[];
}
function MobileNavigationLinks({ items }: MobileNavigationLinksProps) {
    const [openMenu, menuState, moreButtonAriaAttributes] = useMenuState();
    const menuItems = convertNavigationBarItemToMenuItem(items);
    return (<>
            <NavigationBarButton label="Menu" icon={<ChevronDownIcon />} accessibleName="Show Menu" onClick={openMenu} {...moreButtonAriaAttributes}/>
            <SimpleMenu menuState={menuState} items={menuItems} accessibleName="Menu"/>
        </>);
}
interface Position {
    left: number;
    right: number;
}
function NavigationBarLinksList({ items }: {
    items: NavigationBarItemData[];
}) {
    const containerRef = useRef<HTMLUListElement | null>(null);
    const lastContainerWidthRef = useRef<number>(0);
    const lastMoreListItemWidthRef = useRef<number>(0);
    const itemPositions = useRef<Position[] | undefined>();
    const [hasMeasuredItems, setHasMeasuredItems] = useState(false);
    const [numberOfItemsToShow, setNumberOfItemsToShow] = useState<number | undefined>();
    const recalculateItemsToShow = () => {
        const currentItemPositions = itemPositions.current;
        if (currentItemPositions === undefined || currentItemPositions.length === 0) {
            return;
        }
        const currentContainerWidth = lastContainerWidthRef.current;
        const lastItemPosition = currentItemPositions[currentItemPositions.length - 1];
        const showMoreButton = lastItemPosition.right > currentContainerWidth;
        // If all the items fit in the available width, then we don't need to account for the more button at all.
        if (!showMoreButton) {
            setNumberOfItemsToShow(currentItemPositions.length);
            return;
        }
        // Find last index where the more button will fit, the more button will be shown at this index.
        // If it doesn't fit anywhere, it should be shown at the first index.
        const moreButtonIndex = Math.max(findLastIndex(currentItemPositions, (position) => position.left + lastMoreListItemWidthRef.current <= currentContainerWidth), 0);
        setNumberOfItemsToShow(moreButtonIndex);
    };
    useLayoutEffect(() => {
        if (containerRef.current === null) {
            return;
        }
        // Any child list items of the container should be observed for size changes.
        // This can happen when fonts are loaded after the initial mount, which will invalidate all our measurements.
        const itemWidthMap = new Map<HTMLElement, number>();
        const onItemResize = (entries: ResizeObserverEntry[]) => {
            const targets = entries.map((e) => e.target).filter(isHTMLElement);
            const shouldInvalidateMeasurements = targets.some((t) => {
                const previousWidth = itemWidthMap.get(t);
                return previousWidth !== undefined && previousWidth !== t.clientWidth;
            });
            if (shouldInvalidateMeasurements) {
                setHasMeasuredItems(false);
            }
            else {
                targets.forEach((t) => itemWidthMap.set(t, t.clientWidth));
            }
        };
        const itemsResizeObserver = new ResizeObserver(onItemResize);
        // When the container resizes, we recalculate which items to display and which to shove into the 'More' menu.
        const onContainerResize = (entries: ResizeObserverEntry[]) => {
            const container = entries[0].target;
            if (container.clientWidth !== lastContainerWidthRef.current) {
                lastContainerWidthRef.current = container.clientWidth;
                recalculateItemsToShow();
            }
        };
        // Child list items are added removed dynamically on container resize, we need to ensure that they are all observed for size changes.
        const onContainerMutated = (mutations: MutationRecord[]) => {
            const containerChildListMutated = mutations.some((mutation) => mutation.type === "childList");
            if (containerChildListMutated) {
                itemWidthMap.clear();
                itemsResizeObserver.disconnect();
                const items = Array.from(containerRef.current?.children ?? []);
                items.forEach((item) => itemsResizeObserver.observe(item));
            }
        };
        lastContainerWidthRef.current = containerRef.current.clientWidth;
        const containerResizeObserver = new ResizeObserver(throttle(onContainerResize, 100));
        const containerMutationObserver = new MutationObserver(onContainerMutated);
        containerResizeObserver.observe(containerRef.current);
        containerMutationObserver.observe(containerRef.current, { childList: true });
        return () => {
            itemsResizeObserver.disconnect();
            containerResizeObserver.disconnect();
            containerMutationObserver.disconnect();
        };
    }, []);
    useLayoutEffect(() => {
        if (containerRef.current === null || hasMeasuredItems) {
            return;
        }
        const containerOffsetLeft = containerRef.current.offsetLeft;
        itemPositions.current = Array.from(containerRef.current.children)
            .slice(0, -1) // Exclude the last item, which should always be the 'More' button in this state
            .filter(isHTMLElement)
            .map((item) => ({
            left: item.offsetLeft - containerOffsetLeft,
            right: item.offsetLeft - containerOffsetLeft + item.offsetWidth,
        }));
        recalculateItemsToShow();
        setHasMeasuredItems(true);
    }, [hasMeasuredItems]);
    const onMoreListItemRef = useCallback((element: HTMLElement | null) => {
        lastMoreListItemWidthRef.current = element ? element.clientWidth : lastMoreListItemWidthRef.current;
    }, []);
    const [openMoreMenu, moreMenuState, moreButtonAriaAttributes] = useMenuState();
    const itemsToShow = hasMeasuredItems ? items.slice(0, numberOfItemsToShow) : items;
    const itemsToHideUnderMore = hasMeasuredItems ? items.slice(numberOfItemsToShow) : [];
    const showMoreButton = !hasMeasuredItems || itemsToHideUnderMore.length > 0;
    const moreMenuItems = convertNavigationBarItemToMenuItem(itemsToHideUnderMore);
    return (<>
            <ul className={navigationItemsListStyles} ref={containerRef}>
                {itemsToShow.map((item) => (<li key={item.type === "link" ? item.label : item.key}>
                        <NavigationBarItem item={item}/>
                    </li>))}
                {showMoreButton && (<li ref={onMoreListItemRef}>
                        <NavigationBarButton label="More" icon={<ChevronDownIcon />} accessibleName="Open more menu" onClick={openMoreMenu} {...moreButtonAriaAttributes}/>
                        <SimpleMenu menuState={moreMenuState} items={moreMenuItems} accessibleName="More"/>
                    </li>)}
            </ul>
        </>);
}
function convertNavigationBarItemToMenuItem(items: NavigationBarItemData[]) {
    const moreMenuItems: SimpleMenuItem[] = items
        .map((item) => {
        if (item.type === "link") {
            const result: InternalLinkMenuItem = {
                type: "internal-link",
                label: item.label,
                path: item.href,
                showAsActive: item.showLinkAsActive ?? "if path partially matches",
            };
            return result;
        }
        if (item.type === "custom" && item.fallbackLink) {
            const result: InternalLinkMenuItem = {
                type: "internal-link",
                label: item.fallbackLink.label,
                path: item.fallbackLink.href,
                showAsActive: item.fallbackLink.showLinkAsActive ?? "if path partially matches",
            };
            return result;
        }
        return undefined;
    })
        .filter(isNotNull);
    return moreMenuItems;
}
function NavigationBarActionsContainer({ children }: React.PropsWithChildren<{}>) {
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    return <div className={cx(navigationBarActionsContainerStyles, { [navigationBarActionsContainerMobileStyles]: !isLargerThanIpad })}>{children}</div>;
}
NavigationBarActionsContainer.displayName = "NavigationBarActionsContainer";
function useIsLargerThanIpadResolution() {
    return useMediaQuery({ query: `(min-width: 811px)` });
}
// This exists natively in JS but is not in our version of TS, this should be replaced when available.
function findLastIndex<T>(array: T[], callbackFn: (value: T) => boolean) {
    for (let i = array.length - 1; i >= 0; i--) {
        if (callbackFn(array[i])) {
            return i;
        }
    }
    return -1;
}
const navigationBarStyles = css({
    background: themeTokens.color.navigation.background.primary,
    color: themeTokens.color.navigation.link.text.primary,
    fill: themeTokens.color.navigation.link.text.primary,
    padding: `0 ${space[5]}`,
    width: "100%",
    boxSizing: "border-box",
    display: "grid",
    columnGap: space[6],
    //We need an explicit height in order to avoid items/actions from expanding
    //the navbar passed what the design accounts for.
    gridAutoRows: "4rem",
    alignItems: "center",
});
const largeNavigationBarStyles = css({
    gridTemplateColumns: "auto 1fr auto",
    gridTemplateAreas: `"logo items actions"`,
});
const smallNavigationBarStyles = css({
    gridTemplateColumns: "1fr auto",
    gridTemplateAreas: `"logo items" "actions actions"`,
});
const navigationBarActionsContainerStyles = css({
    gridArea: "actions",
});
const navigationBarActionsContainerMobileStyles = css({
    borderTop: `1px solid ${colorScales.navy[700]}`,
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
});
const navigationItemsListStyles = css({
    ...resetStyles.ul,
    flex: 1,
    display: "flex",
    alignItems: "center",
    gap: space[6],
    minWidth: 0,
});

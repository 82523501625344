import { css } from "@emotion/css";
import MuiLinearProgress from "@material-ui/core/LinearProgress";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
/** Props used for the {@link LinearProgress} component */
export interface LinearProgressProps {
    /**
     * The visibility of the component.
     *
     * When this is false, the progress bar is hidden and replaced with an empty space of the same size.
     * This is to prevent the layout shifting as a result of showing or hiding the progress bar.
     */
    show: boolean;
}
/**
 * - Docs: https://www.octopus.design/932c0f1a9/p/913f63-progress
 * - Showcase: https://components.octopus.design/?story=progress--linear
 * @param LinearProgressProps
 * @param LinearProgressProps.show
 * @example
 * ```tsx
 * <LinearProgress show={true} />
 * ```
 */
export const LinearProgress: React.FC<LinearProgressProps> = ({ show }) => {
    if (show) {
        return <MuiLinearProgress key={"progressbar"} role="progressbar" variant={"indeterminate"} classes={{ bar: barStyles, root: rootStyles }} aria-label={"Progress"}/>;
    }
    return <div key={"hidden-spacer"} className={hiddenSpacerStyle}/>;
};
LinearProgress.displayName = "LinearProgress"
LinearProgress.displayName = "LinearProgress";
const linearProgressClasses = {
    root: "MuiLinearProgress-root",
    bar: "MuiLinearProgress-bar",
};
const barStyles = css({
    [`&.${linearProgressClasses.bar}`]: {
        backgroundColor: themeTokens.color.progressBar.foreground.linear,
    },
});
const rootStyles = css({
    [`&.${linearProgressClasses.root}`]: {
        width: "100%",
        backgroundColor: themeTokens.color.progressBar.background.linear,
    },
});
const hiddenSpacerStyle = css({
    width: "100%",
    height: "0.25rem", //This matches <LinearProgress> height so we avoid jankiness from show/hide.
});

import type { ResourceWithSlug } from "./ResourceWithSlug";
import type { NamedResource } from "./namedResource";
import type { SensitiveValue } from "./propertyValueResource";
import type { TenantedDeploymentMode } from "./tenantedDeploymentMode";
export enum AccountType {
    None = "None",
    UsernamePassword = "UsernamePassword",
    Token = "Token",
    SshKeyPair = "SshKeyPair",
    AzureSubscription = "AzureSubscription",
    AzureServicePrincipal = "AzureServicePrincipal",
    AmazonWebServicesAccount = "AmazonWebServicesAccount",
    GoogleCloudAccount = "GoogleCloudAccount"
}
interface AccountResourceLinks {
    Self: string;
    Usages: string;
}
export interface AccountResource extends NamedResource<AccountResourceLinks>, ResourceWithSlug {
    Name: string;
    Description: string;
    EnvironmentIds: string[];
    TenantIds: string[];
    TenantTags: string[];
    AccountType: AccountType;
    TenantedDeploymentParticipation: TenantedDeploymentMode;
}
export interface AzureServicePrincipalAccountResource extends AccountResource {
    SubscriptionNumber: string;
    ClientId: string;
    TenantId: string;
    Password: SensitiveValue;
    AzureEnvironment: string;
    ResourceManagementEndpointBaseUri: string;
    ActiveDirectoryEndpointBaseUri: string;
}
export interface AzureSubscriptionAccountResource extends AccountResource {
    SubscriptionNumber: string;
    CertificateBytes: SensitiveValue;
    CertificateThumbprint: string;
    AzureEnvironment: string;
    ServiceManagementEndpointBaseUri: string;
    ServiceManagementEndpointSuffix: string;
}
export interface SshKeyPairAccountResource extends AccountResource {
    Username: string;
    PrivateKeyFile: SensitiveValue;
    PrivateKeyPassphrase: SensitiveValue;
}
export interface UsernamePasswordAccountResource extends AccountResource {
    Username: string;
    Password: SensitiveValue;
}
export interface TokenAccountResource extends AccountResource {
    Token: SensitiveValue;
}
export interface AmazonWebServicesAccountResource extends AccountResource {
    AccessKey: string;
    SecretKey: SensitiveValue;
}
export interface GoogleCloudAccountResource extends AccountResource {
    JsonKey: SensitiveValue;
}
